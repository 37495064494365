/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import rateLimitComponent from '../common/controller/rate-limit-component';
import standardAnalysisModule from '../common/standard-analysis-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {defaultCurrencyDeriver} from "../line-item/currency-default-calculator";
import orderBudgetsTemplate from "./order-budget-modal.html";
import {lineItemCommons} from "../line-item/line-item-commons";
import moment from "moment/moment";
import {ResourceHelper} from "../../components/util/resource-helper";

const MODULE_NAME = "order-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  rateLimitComponent,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent,
])

  .controller('OrderCtrl', function($stateParams, localUserProfile, readOnly, $uibModal, $state, adnModalLoading, $filter, $translate, adnTimeShifter, LocalNetworkProfile, LocalUserPermissions, Team, User, searchResource, model, modelComms) {
    const ctrl = this;

    const workOutDefaultCurrency = defaultCurrencyDeriver(ctrl, localUserProfile);

    const addSecondForUi = function(budgets) {
      for (let i = 0; i < budgets.length; i++) {
        const prev = i > 0 ? budgets[i - 1] : null;
        const current = budgets[i];
        if (!prev) {
          continue;
        }
        if (moment(prev.modEndDay).diff(moment(current.modStartDay), "seconds") === 0) {
          current.modStartDay = moment(current.modStartDay).add(1, 'second').toDate();
        }
      }
      return budgets;
    };

    const updateBudgets = function(obj) {
      const order = obj || ctrl.model || {};
      const budgets = _.cloneDeep(order.budget) || [];
      _.forEach(budgets, function(b) {
        b.modStartDay = adnTimeShifter.timeShift(b.startDay);
        b.modEndDay = adnTimeShifter.timeShift(b.endDay);
      });
      ctrl.uiBudgets = addSecondForUi(budgets);

      ctrl.model.budgetRollover = _.isBoolean(order.budgetRollover) ? order.budgetRollover : true;
    };

    ctrl.budgetResult = ctrl.budgetResult || {};
    adnFormHelper.setUpForm(ctrl, model, {
      afterInitAfterSave: function(obj) {
        updateBudgets(obj);
      },
      modelComms: modelComms,
      afterSave: function(pOrder) {
        ctrl.budgetResult = ctrl.budgetResult || {};
        ctrl.budgetResult.data = false;

        ctrl.model.advertiser = pOrder.advertiser;
        workOutDefaultCurrency(ctrl.model.advertiser, null, null);
      },
      afterInit: function() {
        if (ctrl.model.objectiveStartDate) {
          ctrl.model.modObjectiveStartDate = adnTimeShifter.timeShift(ctrl.model.objectiveStartDate);
        }
        if (ctrl.model.objectiveEndDate) {
          ctrl.model.modObjectiveEndDate = adnTimeShifter.timeShift(ctrl.model.objectiveEndDate);
        }
        if ($state.previousParams.advertiser) {
          ctrl.model.advertiser = $state.previousParams.advertiser;
        }
        ctrl.blockSubmit = readOnly;
      }
    });

    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.advertiserInfo = !ctrl.isPureMarketplace || $stateParams.isNew;
    ctrl.chartCurrency = _.get(model, ['objectives', 'BUDGET', 'currency']) || _.get(model, ['budget', 0, 'amount', 'currency']);

    const orderCommons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
    orderCommons.setObjectiveFields();
    orderCommons.setBidSpecificationFields();
    orderCommons.setObjectivesAsString();
    orderCommons.setAxAdvertiser();

    ctrl.isNew = $stateParams.isNew;

    ctrl.showTeams = LocalNetworkInfo.obtain().getInfo("teamsCount") > 1 || !LocalNetworkProfile.isPureMarketplacePlatform();

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      if (_.get(ctrl, ['model', 'team', 'id'])) {
        const hydratedTeam = _.find(allTeams, function(t) {
          return ctrl.model.team.id === t.id;
        });
        if (hydratedTeam) {
          ctrl.model.team = hydratedTeam;
          workOutDefaultCurrency(null, ctrl.model.team, null);
        }
      }
      if (allTeams.length === 1 && ctrl.teams.length === 1 && !ctrl.model.team) {
        ctrl.model.team = allTeams[0];
      }
    });

    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true || ctrl.perms.AX_ADVERTISER;
    ctrl.primaryNetworkCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.allowsObjectives = LocalNetworkProfile.isOrderObjectives();
    ctrl.v1Objectives = !_.isEmpty(model.objectives);
    if (ctrl.isAxAdvertiser) {
      ctrl.allowsRateLimits = LocalNetworkProfile.isMarketplaceAdvertiserRateLimits();
      ctrl.rateLimitTypes = ['RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION'];
    } else {
      ctrl.allowsRateLimits = true;
      ctrl.rateLimitTypes = ['IMPRESSION', 'RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'CLICK'];
    }

    ctrl.searchAdvertisers = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserResults = data.searchResults;
      });
    };

    User.query({minimal: true}, function(page) {
      ctrl.users = page.results;
    });

    let hasTimeUpdated = false;
    ctrl.changedObjectiveEndDate = function() {
      if (!hasTimeUpdated && !_.isDate(model.objectiveEndDate)) {
        ctrl.model.modObjectiveEndDate.setHours(23);
        ctrl.model.modObjectiveEndDate.setMinutes(59);
        ctrl.model.modObjectiveEndDate.setSeconds(59, 999);
        hasTimeUpdated = true;
      }
    };

    ctrl.editBudgets = function() {
      ctrl.budgetResult = {
        data: false
      };
      const modalInstance = $uibModal.open({
        template: orderBudgetsTemplate,
        controller: function() {
          const modalCtrl = this;

          const modelOrder = angular.copy(ctrl.model);

          function updateTimes(budget) {
            if (budget.startDay) {
              budget.modStartDay = moment(budget.startDay).toDate();
            }
            if (budget.endDay) {
              budget.modEndDay = moment(budget.endDay).toDate();
            }
            budget.editable = moment(budget.startDay).isAfter(moment()) || moment(budget.endDay).isAfter(moment());
          }

          function addFromScratch() {
            return {
              startDay: moment().toDate(),
              endDay: moment().endOf('month').toDate(),
              editable: true
            };
          }

          modalCtrl.budgetRollover = modelOrder.budgetRollover;
          modalCtrl.moneyCurrency = _.cloneDeep(ctrl.moneyCurrency);
          modalCtrl.budget = _.cloneDeep(ctrl.model.budget) || [];
          if (modalCtrl.budget.length === 0) {
            modalCtrl.budget.push(addFromScratch());
          }

          modalCtrl.addBudgetPeriod = function() {
            if (modalCtrl.budget.length === 0) {
              const budget = addFromScratch();
              updateTimes(budget);
              modalCtrl.budget.push(budget);
              return;
            }
            const refBudget = modalCtrl.budget[modalCtrl.budget.length - 1];
            let newStartDate = moment(refBudget.modEndDay).add(1, "day").startOf('day');
            let budget = {
              startDay: newStartDate.toDate(),
              endDay: moment(newStartDate).endOf('month').toDate()
            };
            updateTimes(budget);
            modalCtrl.budget.push(budget);
          };

          _.forEach(modalCtrl.budget, function(b) {
            updateTimes(b);
          });

          modalCtrl.budget = addSecondForUi(modalCtrl.budget);

          modalCtrl.removePeriod = function(index) {
            _.pullAt(modalCtrl.budget, index);
          };

          modalCtrl.updateBudgets = function() {
            modelOrder.budget = _.cloneDeep(modalCtrl.budget);
            _.forEach(modelOrder.budget, function(b) {
              b.startDay = moment(b.modStartDay).format("YYYY-MM-DD");
              b.endDay = moment(b.modEndDay).format("YYYY-MM-DD");

              ResourceHelper.checkMoney(b, ['amount']);
              delete b.modStartDay;
              delete b.modEndDay;
              delete b.nextPeriodOption;
            });

            modelOrder.budgetRollover = modalCtrl.budgetRollover;

            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();
            modelOrder.savePartial(['budget', 'budgetRollover']).then(function(savedOrder) {
              ctrl.budgetResult.data = true;
              modalCtrl.disabled = false;
              modalInstance.close({order: savedOrder});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        },
        controllerAs: 'modalCtrl',
        size: 'lg',
      });
      modalInstance.result.then(function(data) {
        if (data.order) {
          _.set(ctrl.model, ['budget'], _.get(data, ['order', 'budget']));
          updateBudgets(data.order);
        }
      });
    };

    ctrl.showingChart = '';

    ctrl.onBeforeSubmit = function() {
      if (!_.get(ctrl.model.advertiser, 'id')) {
        ctrl.model.advertiser = null;
      }
      ctrl.model.objectiveStartDate = _.isDate(ctrl.model.modObjectiveStartDate) ? adnTimeShifter.timeShift(ctrl.model.modObjectiveStartDate, true) : null;
      ctrl.model.objectiveEndDate = _.isDate(ctrl.model.modObjectiveEndDate) ? adnTimeShifter.timeShift(ctrl.model.modObjectiveEndDate, true) : null;

      if (!_.get(ctrl.model, ['salespersonUser', 'name']) && _.get(ctrl.model, ['salespersonUser', 'id'])) {
        delete ctrl.model.salespersonUser;
      }
      if (!_.get(ctrl.model, ['adOpsUser', 'name']) && _.get(ctrl.model, ['adOpsUser', 'id'])) {
        delete ctrl.model.adOpsUser;
      }

      if (!_.get(ctrl.model, ['objectives', 'BUDGET', 'amount'])) {
        _.set(ctrl.model, ['objectives', 'BUDGET'], null);
      }
    };
  });

export default MODULE_NAME;